import Vue from 'vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery'
import LoadScript from 'vue-plugin-load-script';
import Toasted from 'vue-toasted';
import i18n from "./i18n";
import axios from "axios";
 
// jquery
global.$ = jQuery;
global.jQuery = jQuery; 

Vue.config.productionTip = false 
Vue.use(LoadScript);
Vue.use(Toasted) ;
Vue.use(axios) ;

 // PrimeVue CLI setup

 import 'primevue/resources/themes/saga-blue/theme.css';
 import 'primevue/resources/primevue.min.css';
 import 'primeicons/primeicons.css'; 
//  import '../public/assets/css/primep2c.css';
 

import Dialog from 'primevue/dialog'; 
Vue.component('Dialog', Dialog);

import Dropdown from 'primevue/dropdown'; 
Vue.component('Dropdown', Dropdown);

import Checkbox from 'primevue/checkbox'; 
Vue.component('Checkbox', Checkbox);
  
import InputNumber from 'primevue/inputnumber'; 
Vue.component('InputNumber', InputNumber);

  
import InputText from 'primevue/inputtext'; 
Vue.component('InputText', InputText);

import Password from 'primevue/password';
Vue.component('Password', Password);

 //Bootstrap
let Bootstrap = require("bootstrap");
Vue.use(Bootstrap); 

import Embed from 'v-video-embed' 
Vue.use(Embed);
 

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
<div></div>
</template>


<script >
export default {
  name: "loadTheme",
  mounted() {
    this.loadTheme();
  },
  methods: {
    emitToParent: function() {
      this.$emit("childToParent");
    },
    loadTheme: function() {
      var self = this;

      // self.emitToParent(); 
      
      const wow = document.createElement("script");
      wow.setAttribute("src", "/Theme/js/wow.min.js");
      wow.async = true;
      document.body.appendChild(wow);
      wow.onload = function() {

        
        const isotope = document.createElement("script");
        isotope.setAttribute("src", "/Theme/js/jquery.isotope.min.js");
        isotope.async = true;
        document.body.appendChild(isotope);
        isotope.onload = function() {


   
        const easing = document.createElement("script");
        easing.setAttribute("src", "/Theme/js/easing.js");
        easing.async = true;
        document.body.appendChild(easing);
        easing.onload = function() {


 
        const owl = document.createElement("script");
        owl.setAttribute("src", "/Theme/js/owl.carousel.js");
        owl.async = true;
        document.body.appendChild(owl);
        owl.onload = function() {


        const validation = document.createElement("script");
        validation.setAttribute("src", "/Theme/js/validation.js");
        validation.async = true;
        document.body.appendChild(validation);
        validation.onload = function() {


        const magnific = document.createElement("script");
        magnific.setAttribute("src", "/Theme/js/jquery.magnific-popup.min.js");
        magnific.async = true;
        document.body.appendChild(magnific);
        magnific.onload = function() {
     
         
        const enquire = document.createElement("script");
        enquire.setAttribute("src", "/Theme/js/enquire.min.js");
        enquire.async = true;
        document.body.appendChild(enquire);
        enquire.onload = function() {
     
        
        const stellar = document.createElement("script");
        stellar.setAttribute("src", "/Theme/js/jquery.stellar.min.js");
        stellar.async = true;
        document.body.appendChild(stellar);


        stellar.onload = function() {
     
                   const plugin = document.createElement("script");
        plugin.setAttribute("src", "/Theme/js/jquery.plugin.js");
        plugin.async = true;
        document.body.appendChild(plugin);
        plugin.onload = function() {
     
        const typed = document.createElement("script");
        typed.setAttribute("src", "/Theme/js/typed.js");
        typed.async = true;
        document.body.appendChild(typed);
        typed.onload = function() {
     
                 
  
        const countTo = document.createElement("script");
        countTo.setAttribute("src", "/Theme/js/jquery.countTo.js");
        countTo.async = true;
        document.body.appendChild(countTo);
        countTo.onload = function() {
       
        const countdown = document.createElement("script");
        countdown.setAttribute("src", "/Theme/js/jquery.countdown.js");
        countdown.async = true;
        document.body.appendChild(countdown);
        countdown.onload = function() {
      
        const designesia = document.createElement("script");
        designesia.setAttribute("src", "/Theme/js/designesia.js");
        designesia.async = true;
        document.body.appendChild(designesia);
        designesia.onload = function() {
     
                 self.emitToParent();

        };
             
        };
             

        };
        };


        };
        

        };
        



        };
        

        };



        };



        };



        };

        };


      };
    }
  }
};
</script>
 

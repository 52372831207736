<template>
  <div>
    <loadTheme v-on:childToParent="ThemeLoadDone"></loadTheme>

    <div id="wrapper" v-if="themeLoad">
      <div
        id="topbar"
        class="text-white bg-color"
        style="background-size: cover"
      >
        <div class="container" style="background-size: cover">
          <div class="topbar-left" style="background-size: cover">
            <span class="topbar-widget tb-social">
              <a href="https://api.whatsapp.com/send?phone=966500615070" target="_blank"><i class="fa fa-whatsapp fa-lg"></i></a>

              <a href="https://twitter.com/p2capp" target="_blank"
                ><i class="fa fa-twitter"></i
              ></a>
              <a href="https://linkedin.com/company/p2capp" target="_blank"
                ><i class="fa fa-linkedin fa-lg"></i
              ></a>
              
            </span>
          </div>

          <div class="topbar-right sm-hide" style="background-size: cover">
            <span class="topbar-widget tb-phone" style="direction: ltr"
              >
              
              +966 50 061 5070
              
              <!-- 920033187 -->

            </span>
            <span class="topbar-widget tb-email" style="direction: ltr"
              >sales@iepm.com.sa</span
            >
            <router-link
              :to="'/'+$t('lang')+'/Tour'"
              style="direction: ltr"
              class="topbar-widget tb-opening-hours"
            >
              {{ $t("Tour") }}
            </router-link>
          </div>
          <div class="clearfix" style="background-size: cover"></div>
        </div>
      </div>

      <!-- header begin -->
      <header class="header-light transparent scroll-light">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div class="align-self-center header-col-left">
                  <!-- logo begin -->
                  <div id="logo">
                    <router-link :to="'/'+$t('lang')+'/home'">
                      <img class="dark" alt="" src="/Theme/images/logo-2.png" />
                      <img
                        class="light"
                        alt=""
                        src="/Theme/images/logo-2-light.png"
                      />
                    </router-link>
                  </div>
                  <!-- logo close -->
                </div>
                <div class="align-self-center ml-auto header-col-mid">
                  <!-- mainmenu begin -->
                  <ul id="mainmenu">
                     <li>
                      <router-link :to="'/'+$t('lang')+'/home'">{{
                        $t("Home")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="'/'+$t('lang')+'/AboutUs'">{{
                        $t("About")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="'/'+$t('lang')+'/Solutions'">{{
                        $t("Solutions")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="'/'+$t('lang')+'/Features'">{{
                        $t("Features")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="'/'+$t('lang')+'/Partners'">{{
                        $t("Partners")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="'/'+$t('lang')+'/Pricing'">{{
                        $t("Pricing")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="'/'+$t('lang')+'/Contact'">{{
                        $t("Contact")
                      }}</router-link>
                    </li>
                    <li class="d-list-item d-sm-none">
                      <router-link
                        :to="'/'+$t('lang')+'/Tour'"
                        style="direction: ltr" 
                      >
                        {{ $t("Tour") }}
                      </router-link>
                    </li>

                    <li v-if="langEN" class="d-list-item d-sm-none">
                      <a href="#!" @click="changeLang('ar')">اللغة العربية</a>
                    </li>
                    <li v-if="!langEN" class="d-list-item d-sm-none">
                      <a href="#!" @click="changeLang('en')">English</a>
                    </li>
                  </ul>
                </div>
                <div class="align-self-center ml-auto header-col-right">
                  <!-- <router-link class="btn-custom" :to="'/'+$t('lang')+'/login'"><i class="fa fa-arrow-right"></i> Sign In</router-link>   -->
                  <a
                    href="#!"
                    v-if="langEN"
                    @click="changeLang('ar')"
                    class="btn-custom mr-2 bg-color-2 d-none d-sm-inline"
                    >اللغة العربية</a
                  >
                  <a
                    href="#!"
                    v-if="!langEN"
                    @click="changeLang('en')"
                    class="btn-custom mr-2 bg-color-2 d-none d-sm-inline"
                    >English</a
                  >

                  
                  <a href="https://accounts.p2c.sa/login" class="btn-custom" target="_blank">
                    
                    {{ $t("SignIn") }}

                    <i class="fa fa-arrow-right"></i>
                  </a>

                  <span id="menu-btn"></span>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- <SDheader></SDheader> -->
      <div class="no-bottom no-top" id="content">
        <div id="top"></div>
        <router-view />
      </div>
      <SDfooter></SDfooter>
      <!-- <a
        href="https://api.whatsapp.com/send?phone=966500615070"
        class="whatsapp"
      >
        <img class="dark" alt="" src="/assets/images/whatsapp.png" />
      </a> -->
    </div>
  </div>
</template>

<script> 
import SDfooter from "@/components/siteVisual/footer.vue";
import loadTheme from "@/components/ThemeLoad";
import $ from "jquery";
export default {
  name: "siteLayout",
  data() {
    return {
      checked: false,
      themeLoad: false,
      langEN: true, 
      // currentLang: localStorage.getItem("loc") 
    };
  },
  components: {
    // SDheader,
    SDfooter,
    loadTheme,
  },
  methods: {
    ThemeLoadDone: function () {
      this.themeLoad = true;
    },
    custom_bg: function () {
      $("body,div,section,span").css("background-color", function () {
        return $(this).data("bgcolor");
      });
      $("body,div,section").css("background", function () {
        return $(this).data("bgimage");
      });
      $("body,div,section").css("background-size", function () {
        return "cover";
      });
    },
    changeLang: function (lang) {
      if (lang == "ar") { 
        window.location = window.location.toString().replace('/en/','/ar/'); 
      }
      if (lang == "en") { 
        window.location = window.location.toString().replace('/ar/','/en/'); 
      }
    },
    loadLang: function (lang) {
      if (lang == "ar") {
        this.langEN = false;
        this.$i18n.locale = "ar"; 
        localStorage.setItem("loc", "ar");
        $('[data-id="lang-font"]').attr("href", "/assets/css/ar-font.css");
        $('[data-id="lang-style"]').attr("href", "/assets/css/style-ar.css");  
          }
      if (lang == "en") {
        this.langEN = true;
        this.$i18n.locale = "en"; 
        localStorage.setItem("loc", "en");
        $('[data-id="lang-font"]').attr("href", "/assets/css/en-font.css");
        $('[data-id="lang-style"]').attr("href", "/assets/css/style-en.css");  
       }
    },
  },
  created: function () {},
  mounted() {
    if (window.location.href.indexOf("/en/") > -1) {
     this.loadLang('en');
    }
    else if (window.location.href.indexOf("/ar/") > -1) {
     this.loadLang('ar');
    }
    else{ 
      this.loadLang('ar');
    }
 
    
  },
};
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Site from "@/layouts/Site.vue";
import Auth from "@/layouts/Auth.vue";

Vue.use(VueRouter)

const routes = [

  
  {
    path: '/blank',
    name: 'Blank',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Blank.vue')
  }

  ,

  {
    path: "/",
    redirect: "/ar/home",
    component: Site,
    children: [
      // {
      //   path: "/Site/login",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/login.vue')
      // }, 
      {
        path: "/ar/home",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/home.vue'),
        meta: {
          title: 'P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            }
            // ,
            // {
            //   property: 'og:description',
            //   content: 'The home page of our example app.'
            // }
          ]
        }
      }, 
      {
        path: "/en/home",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/home.vue'),
        meta: {
          title: 'P2C | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      },
      // {
      //   path: "/Site/Tour",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/Tour.vue')
      // }, 
      {
        path: "/ar/Tour",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Tour.vue'),
        meta: {
          title: 'جولة P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            } 
          ]
        }
      }, 
      {
        path: "/en/Tour",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Tour.vue'),
        meta: {
          title: 'P2C Tour | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      },
      // {
      //   path: "/Site/AboutUs",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/AboutUs.vue')
      // },
      
      {
        path: "/ar/AboutUs",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/AboutUs.vue'),
        meta: {
          title: 'من نكون P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            } 
          ]
        }
      }, 
      {
        path: "/en/AboutUs",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/AboutUs.vue'),
        meta: {
          title: 'P2C About Us | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      },
      // {
      //   path: "/Site/Solutions",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/Solutions.vue')
      // },
      {
        path: "/ar/Solutions",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Solutions.vue'),
        meta: {
          title: 'الحلول P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            } 
          ]
        }
      }, 
      {
        path: "/en/Solutions",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Solutions.vue'),
        meta: {
          title: 'P2C Solutions | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      },
      // {
      //   path: "/Site/Features",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/Features.vue')
      // } ,
      {
        path: "/ar/Features",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Features.vue'),
        meta: {
          title: 'المزايا P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            } 
          ]
        }
      }, 
      {
        path: "/en/Features",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Features.vue'),
        meta: {
          title: 'P2C Features | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      },
      // {
      //   path: "/Site/Partners",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/Partners.vue')
      // },
      {
        path: "/ar/Partners",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Partners.vue'),
        meta: {
          title: 'الشركاء P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            } 
          ]
        }
      }, 
      {
        path: "/en/Partners",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Partners.vue'),
        meta: {
          title: 'P2C Partners | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      },
      // {
      //   path: "/Site/Pricing",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/Pricing.vue')
      // }
      // ,
      {
        path: "/ar/Pricing",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Pricing.vue'),
        meta: {
          title: 'الأسعار P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            } 
          ]
        }
      }, 
      {
        path: "/en/Pricing",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Pricing.vue'),
        meta: {
          title: 'P2C Pricing | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      },

      {
        path: "/ar/Pricing2",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Pricing2.vue'),
        meta: {
          title: 'الأسعار P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            } 
          ]
        }
      }, 
      {
        path: "/en/Pricing2",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Pricing2.vue'),
        meta: {
          title: 'P2C Pricing | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      },
      // {
      //   path: "/Site/Contact",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/Contact.vue')
      // }
      // ,
      {
        path: "/ar/Contact",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Contact.vue'),
        meta: {
          title: 'اتصل بنا P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            } 
          ]
        }
      }, 
      {
        path: "/en/Contact",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Contact.vue'),
        meta: {
          title: 'P2C Contact US | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      },
      // {
      //   path: "/Site/PrimeTest",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/PrimeTest.vue')
      // },
      // {
      //   path: "/Site/SignUp",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/SignUp.vue')
      // },
      // {
      //   path: "/Site/Subscription",
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Site/Subscription.vue')
      // },
    
      {
        path: "/ar/Subscription",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Subscription.vue'),
        meta: {
          title: 'الإشتراك P2C | نظام إدارة الاستراتيجية والمشاريع والمهام',
          metaTags: [
            {
              name: 'description',
              content: 'P2C منصة سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، لتحقيق الأهداف الاستراتيجية، وموائمة مسار العمل من خلال مؤشرات مرتبطة ببطاقات الأداء المتوازن '
            } 
          ]
        }
      }, 
      {
        path: "/en/Subscription",
        component: () => import(/* webpackChunkName: "about" */ '../views/Site/Subscription.vue'),
        meta: {
          title: 'P2C Subscription | Strategy, Projects and Tasks Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'P2C is a set of cloud-based integrated business management modules, To achieve business strategic goals, Evaluating the KPIs’ results based on balance scorecard'
            } 
          ]
        }
      }

      
  
    ],
  }
  ,

  {
    path: "/Auth",
    redirect: "/Auth/account",
    component: Auth,
    children: [
      {
        path: "/Auth/test",
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/test.vue')
      }
    ],
  }
  ,
{
  path: "/TermAndConditions",
  component: () => import(/* webpackChunkName: "about" */ '../views/Terms/TermAndConditions.vue') 
} 
,
{
  path: "/en/TermAndConditions",
  component: () => import(/* webpackChunkName: "about" */ '../views/Terms/TermAndConditions.vue') 
} 
,
{
  path: "/ar/TermAndConditions",
  component: () => import(/* webpackChunkName: "about" */ '../views/Terms/TermAndConditions.vue') 
}
,
{
  path: "/PrivacyPolicy",
  component: () => import(/* webpackChunkName: "about" */ '../views/privacy/PrivacyPolicy.vue') 
} 
,
{
  path: "/en/PrivacyPolicy",
  component: () => import(/* webpackChunkName: "about" */ '../views/privacy/PrivacyPolicy.vue') 
} 
,
{
  path: "/ar/PrivacyPolicy",
  component: () => import(/* webpackChunkName: "about" */ '../views/privacy/PrivacyPolicy.vue') 
}
 
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode: 'history'
})
 

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

// ...

export default router
